<script setup lang="ts">
import { events } from './Events'
import { router } from '@inertiajs/vue3'

const authStore = useAuthStore()
const stateStore = useStateStore()
const contactStore = useContactStore()
const conversationStore = useConversationsStore()

const userOnline = markUserOnline()
const elReconnectModal = ref<HTMLDialogElement>()
const openReconnectModal = ref(false)

const toggleReconnectModal = () => {
  if (!elReconnectModal.value) return

  openReconnectModal.value = !openReconnectModal.value
  if (openReconnectModal.value) {
    elReconnectModal.value.showModal()
  } else {
    elReconnectModal.value.close()
  }
}
const reconnect = () => window.location.reload()

const disconnect = () => {
  window.Echo.disconnect()
  userOnline.pause()
  document.title = 'Desconectado'
}

const handleSocketDisconnect = (
  action: 'DISCONNECT' | 'FORCE_CONNECTION' | 'REFRESH' = 'DISCONNECT',
) => {
  if (openReconnectModal.value === true && action === 'DISCONNECT') return

  if (action === 'DISCONNECT') {
    toggleReconnectModal()
    disconnect()
  } else if (action === 'REFRESH') {
    if (!refresher()) {
      handleSocketDisconnect('DISCONNECT')
    }
  } else if (action === 'FORCE_CONNECTION') {
    if (!refresher()) {
      toggleReconnectModal()
    }
  }
}

onMounted(() => {
  nextTick(() => {
    const socketConnection = window.Echo.connector.pusher.connection

    socketConnection
      .bind('connected', async () => {
        authStore.socketIsReady = true
      })
      .bind('disconnected', () => {
        handleSocketDisconnect('FORCE_CONNECTION')
      })
      .bind('unavailable', () => {
        handleSocketDisconnect('FORCE_CONNECTION')
      })

    window.UserSocket.listen('.force-logout', () => {
      disconnect()
      router.visit('/login', { replace: true, preserveState: false })
    })

    window.ChatSocket.listen(
      events.v2.UnlistContact,
      (event: IPChat.Socket.UnlistContact) =>
        conversationStore.unlistContact({ event }),
    )
      .listen(events.v2.MessageEvent, (event: IPChat.Socket.NewMessage) =>
        conversationStore.newMessage({ event }),
      )
      .listen(
        events.v2.LinkConversationToAttendant,
        (event: IPChat.Socket.LinkConversationToAttendant) =>
          conversationStore.linkConversationToAttendant({ event }),
      )
      .listen(
        events.v2.SetExternalIdOnMessage,
        (event: IPChat.Socket.SetExternalIdOnMessage) =>
          conversationStore.updateMessage({ event }),
      )
      .listen(
        events.v2.FinishChatState,
        (event: IPChat.Socket.FinishChatState) =>
          conversationStore.transferActiveToFinished({
            contactId: event.key.contactId,
            instanceId: event.key.instanceId,
          }),
      )

    window.GroupSocket.listen(
      events.v2.GroupAckEvent,
      (event: IPChat.Socket.Ack) => conversationStore.ack({ event }),
    ).listen(events.v2.GroupMessageEvent, (event: IPChat.Socket.NewMessage) =>
      conversationStore.newMessage({ event }),
    )

    // State Store
    window.CompanySocket.listen(
      events.MarkConversationAs,
      (event: IPChat.Socket.MarkConversationAs) => {
        const { key, as } = event
        switch (as) {
          case 'unread':
            stateStore.markAsUnread({
              stateId: key.stateId,
              ignoreSyncToBackend: true,
            })
            break
          case 'read':
            stateStore.markAsRead({ stateId: key.stateId })
            break
        }
      },
    )

    // Conversation Store
    window.CompanySocket.listen(
      events.CompanyIsDisabled,
      (event: IPChat.Socket.CompanyIsDisabled) => {
        authStore.toggleCompanyStatus(event.disabled)
      },
    )
      .listen(events.v2.AckEvent, (event: IPChat.Socket.Ack) =>
        conversationStore.ack({ event }),
      )
      .listen(events.v2.MessageEdit, (event: IPChat.Socket.MessageEdit) =>
        conversationStore.messageEdit({ event }),
      )
      .listen(events.v2.RevokeMessage, (event: IPChat.Socket.RevokeMessage) =>
        conversationStore.revokeMessage({ event }),
      )
      .listen(
        events.v2.ReactionMessage,
        (event: IPChat.Socket.ReactionMessage) =>
          conversationStore.reactionMessage({ event }),
      )
      .listen(events.v2.FishingNewMessage, (event: IPChat.Socket.NewMessage) =>
        conversationStore.newMessage({ event }),
      )
      .listen(
        events.v2.TransactionalMessage,
        (event: IPChat.Socket.NewMessage) =>
          conversationStore.newMessage({ event }, false),
      )
      .listen(
        events.v2.UnfishAttendant,
        (event: IPChat.Socket.UnfishAttendant) =>
          conversationStore.unfishAttendant({ event }),
      )
      .listen(
        events.v2.FishingAttendant,
        (event: IPChat.Socket.FishingAttendant) =>
          conversationStore.fishingAttendant({ event }),
      )
      .listen(
        events.v2.FailedToSendMessage,
        (event: IPChat.Socket.FailedToSendMessage) =>
          conversationStore.failedToSendMessage({ event }),
      )
      .listen(
        events.v2.LinkConversationToDepartment,
        (event: IPChat.Socket.LinkConversationToDepartment) =>
          conversationStore.linkConversationToDepartment({ event }),
      )
      .listen(
        events.v2.NotifyThatTheConversationHasBeenTransferred,
        (event: IPChat.Socket.NotifyThatTheConversationHasBeenTransferred) =>
          conversationStore.transferredConversation({ event }),
      )
      .listen(
        events.v2.DeletedContact,
        async (event: IPChat.Socket.DeletedContact) => {
          /**
           * Apagar a conversation
           * Apagar o contato da lista
           * Apagar os states relacionados
           */

          await conversationStore.deleteConversationFromContactId({ event })
          contactStore.contactDeleted({ contactId: event.key.contactId })
        },
      )

    window.CompanySocket.here((users: IPChat.Auth.PresenceList[]) =>
      users.forEach((user) => authStore.setPresence(user)),
    )
      .joining((user: IPChat.Auth.PresenceList) => authStore.setPresence(user))
      .leaving((user: IPChat.Auth.PresenceList) =>
        authStore.removePresence(user),
      )

    // Contact Store
    window.CompanySocket.listen(
      events.v2.NewContact,
      (event: IPChat.Socket.NewContact) =>
        contactStore.addNewContact({ event }),
    ).listen(events.v2.ContactEdited, (event: IPChat.Socket.ContactEdited) =>
      contactStore.contactEdited({ event }),
    )

    if (authStore.isObserver) {
      window.ObserverSocket.listen(
        events.v2.MessageEvent,
        (event: IPChat.Socket.NewMessage) => {
          if (event.key.attendantId !== authStore.user.id) {
            conversationStore.newMessage({ event }, false)
          }
        },
      )
        .listen(
          events.v2.ChatStateInitialized,
          (event: IPChat.Socket.ChatStateInitialized) =>
            conversationStore.appendNewConversation({ event }),
        )
        .listen(
          events.v2.FinishChatState,
          (event: IPChat.Socket.FinishChatState) => {
            if (event.key.attendantId !== authStore.user.id) {
              conversationStore.transferActiveToFinished({
                contactId: event.key.contactId,
                instanceId: event.key.instanceId,
              })
            }
          },
        )
        .listen(
          events.v2.ChatStateReactivated,
          (event: IPChat.Socket.ChatStateReactivated) =>
            conversationStore.reactivated({ event }),
        )
        .listen(
          events.v2.ChatStateTakenFromBot,
          (event: IPChat.Socket.ChatStateTakenFromBot) =>
            conversationStore.chatStateTakenFromBot({ event }),
        )
        .listen(
          events.v2.SetExternalIdOnMessage,
          (event: IPChat.Socket.SetExternalIdOnMessage) => {
            if (event.key.attendantId !== authStore.user.id) {
              conversationStore.updateMessage({ event })
            }
          },
        )
        .listen(
          events.v2.LinkConversationToAttendant,
          (event: IPChat.Socket.LinkConversationToAttendant) => {
            if (event.key.attendantId !== authStore.user.id) {
              conversationStore.linkConversationToAttendant({ event })
            }
          },
        )
    }
  })
})

onBeforeUnmount(() => {
  authStore.$reset()
  handleSocketDisconnect()
})
</script>

<template>
  <dialog
    ref="elReconnectModal"
    id="elReconnectModal"
    class="tw-daisy-modal tw-daisy-modal-bottom sm:tw-daisy-modal-middle"
  >
    <div class="tw-daisy-modal-box">
      <h3 class="tw-text-lg tw-font-bold">Você está desconectado</h3>
      <div class="tw-pt-2">
        O chat está desconectado ou aberto em outra janela. Por favor, verifique
        também sua conexão com a internet. Clique em
        <span class="tw-font-medium">“Reconectar”</span>
        para restabelecer a conexão novamente.
      </div>
      <div class="tw-daisy-modal-action">
        <button
          class="tw-daisy-btn tw-daisy-btn-error"
          @click="toggleReconnectModal()"
        >
          Fechar
        </button>
        <button @click="reconnect" class="tw-daisy-btn tw-daisy-btn-info">
          Reconectar
        </button>
      </div>
    </div>
  </dialog>
  <slot />
</template>
