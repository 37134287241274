<script setup lang="ts">
import {
  HomeIcon,
  Cog8ToothIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  TagIcon,
  CodeBracketIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleLeftIcon,
  BoltIcon,
  ArrowTrendingUpIcon,
  ServerStackIcon,
} from '@heroicons/vue/24/outline'
import _ from 'lodash'
import Navbar from './Components/Navbar.vue'

const agencyStore = useAgencyStore()
const useAuth = useAuthStore()

const drawerId = 'dashboard-sidebar-menu'
</script>
<template>
  <div class="tw-daisy-drawer full-size lg:tw-daisy-drawer-open">
    <input :id="drawerId" type="checkbox" class="tw-daisy-drawer-toggle" />

    <div class="tw-daisy-drawer-content main-content">
      <div class="tw-flex tw-h-full tw-flex-col">
        <Navbar />

        <div class="content-wrapper">
          <slot />
        </div>
      </div>
    </div>

    <div class="tw-daisy-drawer-side leftmenu-wrapper">
      <label
        :for="drawerId"
        aria-label="close sidebar"
        class="tw-daisy-drawer-overlay"
      ></label>

      <div class="side-drawer">
        <div class="company-logo">
          <template v-if="_.isEmpty(agencyStore.agency)">
            <span class="tw-daisy-btn tw-daisy-btn-ghost tw-text-xl">
              IPCHAT
            </span>
          </template>
          <template v-else>
            <template v-if="_.isEmpty(agencyStore.agency.logo)">
              {{ agencyStore.agency.platform_title ?? 'Multiatendimento' }}
            </template>
            <img
              v-else
              :src="agencyStore.agency.logo"
              class="tw-max-w-[400px] tw-max-h-12"
            />
          </template>
        </div>
        <ul class="tw-daisy-menu tw-daisy-menu-lg">
          <li>
            <Link
              :href="route('dashboard.index')"
              :class="{
                'tw-daisy-active': route().current('dashboard.index'),
              }"
            >
              <HomeIcon class="tw-size-5 icon" />
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              :href="route('dashboard.v2.api.index')"
              :class="{
                'tw-daisy-active': route().current('dashboard.v2.api.index'),
              }"
            >
              <CodeBracketIcon class="tw-size-5 icon" />
              API
            </Link>
          </li>
          <li>
            <Link
              :href="route('chat.index')"
              :class="{
                'tw-daisy-active': route().current('chat.index'),
              }"
            >
              <ChatBubbleLeftIcon class="tw-size-5 icon" />
              Chat
            </Link>
          </li>
          <div class="tw-daisy-divider"></div>
          <li>
            <details
              :open="
                route().current('dashboard.v2.service.*') ||
                route().current('dashboard.v2.conversation*')
              "
            >
              <summary>
                <ChatBubbleLeftRightIcon class="tw-size-5 icon" />
                Atendimentos
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.v2.conversation.export.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.conversation.export.index',
                      ),
                    }"
                  >
                    Conversas Exportadas
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.v2.service.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.service.index',
                      ),
                    }"
                  >
                    Listar Atendimentos
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.v2.service.protocol.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.service.protocol.index',
                      ),
                    }"
                  >
                    Pesquisar Protocolos
                  </Link>
                </li>
                <li>
                  <Link
                    :href="
                      route('dashboard.v2.service.setting.quick.message.index')
                    "
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.service.setting.quick.message.index',
                      ),
                    }"
                  >
                    Mensagens Rápidas
                  </Link>
                </li>
                <li>
                  <Link
                    :href="
                      route('dashboard.v2.service.setting.reason.message.index')
                    "
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.service.setting.reason.message.index',
                      ),
                    }"
                  >
                    Motivos de Finalização
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details :open="route().current('dashboard.v2.setting.*')">
              <summary>
                <Cog8ToothIcon class="tw-size-5 icon" />
                Configurações
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.v2.setting.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.setting.index',
                      ),
                    }"
                    replace
                    preserve-state
                  >
                    Configurações Gerais
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.v2.setting.bussiness.time.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.setting.bussiness.time.index',
                      ),
                    }"
                    replace
                    preserve-state
                  >
                    Horários de Atendimento
                  </Link>
                </li>
                <li>
                  <Link
                    :href="
                      route('dashboard.v2.setting.satisfaction.survey.index')
                    "
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.setting.satisfaction.survey.index',
                      ),
                    }"
                    replace
                    preserve-state
                  >
                    Pesquisa de Satisfação
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details :open="route().current('dashboard.contact.*')">
              <summary>
                <UserCircleIcon class="tw-size-5 icon" />
                Contatos
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.contact.create')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.contact.create',
                      ),
                    }"
                  >
                    Cadastrar contato
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.contact.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.contact.index',
                      ),
                    }"
                  >
                    Listar contatos
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details
              :open="
                route().current('dashboard.v2.chatbot.*') ||
                route().current('dashboard.chatbot*')
              "
            >
              <summary>
                <BoltIcon class="tw-size-5 icon" />
                Chatbot
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.v2.chatbot.setting.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.chatbot.setting.index',
                      ),
                    }"
                  >
                    Configurações
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.v2.chatbot.form.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.chatbot.form.index',
                      ),
                    }"
                  >
                    Formulários
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.v2.chatbot.message.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.chatbot.message.index',
                      ),
                    }"
                  >
                    Mensagens Automáticas
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.chatbot.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.chatbot.index',
                      ),
                    }"
                  >
                    Menu de Atendimento
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details :open="route().current('dashboard.department.*')">
              <summary>
                <UserGroupIcon class="tw-size-5 icon" />
                Departamentos
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.department.create')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.department.create',
                      ),
                    }"
                  >
                    Cadastrar departamento
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.department.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.department.index',
                      ),
                    }"
                  >
                    Listar departamentos
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details :open="route().current('dashboard.tag.*')">
              <summary>
                <TagIcon class="tw-size-5 icon" />
                Etiquetas
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.tag.create')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.tag.create',
                      ),
                    }"
                  >
                    Cadastrar etiqueta
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.tag.index')"
                    :class="{
                      'tw-daisy-active': route().current('dashboard.tag.index'),
                    }"
                  >
                    Listar etiquetas
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <Link
              :href="route('dashboard.v2.group.index')"
              :class="{
                'tw-daisy-active': route().current('dashboard.v2.group.index'),
              }"
            >
              <UserGroupIcon class="tw-size-5 icon" />
              Grupos
            </Link>
          </li>
          <li>
            <details :open="route().current('dashboard.v2.integration.*')">
              <summary>
                <ServerStackIcon class="tw-size-5 icon" />
                Integrações
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.v2.integration.wpp.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.integration.wpp.index',
                      ),
                    }"
                  >
                    WhatsApp
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <Link
              :href="route('dashboard.v2.report.index')"
              :class="{
                'tw-daisy-active': route().current('dashboard.v2.report.*'),
              }"
            >
              <ArrowTrendingUpIcon class="tw-size-5 icon" />
              Relatórios
            </Link>
          </li>
          <li>
            <details :open="route().current('dashboard*.user.*')">
              <summary>
                <UsersIcon class="tw-size-5 icon" />
                Usuários
              </summary>
              <ul>
                <li>
                  <Link
                    :href="route('dashboard.user.create')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.user.create',
                      ),
                    }"
                  >
                    Cadastrar usuário
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('dashboard.user.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.user.index',
                      ),
                    }"
                  >
                    Listar usuários
                  </Link>
                </li>
                <li v-if="useAuth.hasManyCompanies">
                  <Link
                    :href="route('dashboard.v2.user.import.index')"
                    :class="{
                      'tw-daisy-active': route().current(
                        'dashboard.v2.user.import.index',
                      ),
                    }"
                  >
                    Importar usuários
                  </Link>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.full-size {
  @apply tw-w-full tw-h-full;
}

.main-content {
  @apply tw-overflow-auto tw-w-full tw-h-[100vh];
}

.content-wrapper {
  @apply tw-flex-grow tw-p-4 tw-bg-[var(--content-background)] tw-transition-all;
}

.leftmenu-wrapper {
  @apply tw-z-[11] lg:tw-border-r-2 lg:tw-border-base-200;
}

.leftmenu-wrapper .side-drawer {
  @apply tw-bg-base-200 tw-min-h-full tw-rounded-box tw-w-80 tw-p-4 tw-z-auto lg:tw-bg-base-100;
}

.leftmenu-wrapper .company-logo {
  @apply tw-flex tw-items-center tw-justify-center tw-h-20;
}
</style>
